<template>
  <div class="text-right">
    <b-input-group>
      <template #prepend>
        <b-input-group-text>$</b-input-group-text>
      </template>
      <b-input :value="current"
               type="number"
               tabindex="990"
               class="text-right budget-adjustor-input"
               autofocus
               @keydown.enter.prevent="blurField($event, true)"
               @blur="blurField($event)"
               @input="updateField('current', $event)" />
    </b-input-group>
    <div class="small text-center">-or-</div>
    <b-input-group>
      <b-input-group-prepend>
        <t-dropdown :text="(addition) ? 'Add (+)' : 'Sub (-)'"
                    no-caret
                    toggle-class="budget-adjustor-input"
                    menu-class="budget-adjustor-input">
          <t-dropdown-item @click="setAddition()">Increase By</t-dropdown-item>
          <t-dropdown-item @click="setAddition(false)">Decrease By</t-dropdown-item>
        </t-dropdown>
        <b-input-group-text>$</b-input-group-text>
      </b-input-group-prepend>
      <b-input :value="adjustment"
               type="number"
               tabindex="991"
               class="text-right budget-adjustor-input"
               @keydown.enter.prevent="blurField($event, true)"
               @blur="blurField($event)"
               @input="updateField('adjustment', $event)" />
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'budgetAdjuster',
  props: {
    value: {}
  },
  data () {
    return {
      current: 0,
      adjustment: 0,
      addition: true
    }
  },
  watch: {
    value: {
      handler (val) {
        let valCopy = val

        if (typeof valCopy === 'string') {
          valCopy = valCopy.replace(/,/g, '')
        }

        const numeric = parseFloat(valCopy)
        const currencyVal = parseFloat(this.$filters.currency(numeric, 2, { symbol: false }).replace(/,/g, ''))

        this.$setCompat(this, 'current', currencyVal)
      },
      immediate: true
    }
  },
  computed: {
    workingValue () {
      if (Number.isNaN(this.current)) {
        return 0.00
      }

      if (Number.isNaN(this.adjustment)) {
        return this.current
      }

      if (this.addition) {
        return this.current + this.adjustment
      }

      return this.current - this.adjustment
    }
  },
  methods: {
    emitUp () {
      this.$emit('input', this.workingValue)
    },
    blurField (e, enter) {
      if (e?.relatedTarget?.classList.contains('budget-adjustor-input')) {
        return
      }

      this.emitUp()

      if (enter) {
        this.$emit('enter')
      }
    },
    updateField (key, val) {
      const pf = parseFloat(val)
      this.$setCompat(this, key, Number.isNaN(pf) ? 0 : pf)
    },
    setAddition (val = true) {
      this.$setCompat(this, 'addition', val)

      if (this.adjustment !== 0) {
        this.emitUp()
      }
    }
  }
}
</script>

<style scoped>

</style>
